import BurgerToggle from 'components/Domain/BurgerMenu/BurgerToggle';
import Footer from 'components/Domain/Footer';
import { useDimensions } from 'components/Domain/Header/useDimension';
import Arrow from 'components/Layout/Arrow';
import { AnimateSharedLayout, motion, useCycle } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useRef, useState } from 'react';
import SimpleBarReact from 'simplebar-react';
import cssGlobal from 'styles/global.scss';
import vars from 'styles/vars.scss';
import css from './BurgerMenu.scss';

const BurgerMenu = ({ navLinks = [], selected = '', hash = '', setShowDownloadModal = () => {} }) => {
  const router = useRouter();
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  const [hovered, setHovered] = useState(null);
  const scrollDOM = useRef();

  const savedVariant = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at top right)`,
      transition: {
        type: 'spring',
        stiffness: 100,
        restDelta: 2
      }
    }),
    closed: {
      clipPath: 'circle(1px at top right)',
      transition: {
        delay: 0.5,
        type: 'spring',
        stiffness: 400,
        damping: 40
      }
    }
  };

  return (
    <div
      className={[css.BurgerMenu].join(' ')}
    >
      <AnimateSharedLayout>
        <motion.div
          initial={false}
          animate={isOpen ? 'open' : 'closed'}
          custom={height}
          ref={containerRef}
          className={[css.Content].join(' ')}
        >
          <motion.div
            className={[css.Wrapper].join(' ')}
            variants={{
              open: {
                x: 0
              },
              closed: {
                x: '-100vw'
              }
            }}
          >
            <SimpleBarReact
              id={'subMenu'}
              className={css.Scroll}
              autoHide={false}
              ref={scrollDOM}
              onMouseLeave={() => {
                setTimeout(() => {
                  setHovered(null);
                }, 250);
              }}
            >
              <motion.nav
                className={[css.Nav].join(' ')}
              >
                {navLinks.map((navLink, index) => {
                  const isHovered = (hovered && hovered.pathname === navLink.pathname);
                  const isSelected = (selected && selected.pathname === navLink.pathname);
                  const MenuVariant = {
                    open: {
                      y: 0,
                      opacity: 1,
                      transition: {
                        y: {
                          stiffness: 1000,
                          velocity: -100
                        }
                      }
                    },
                    closed: {
                      y: 50,
                      opacity: 0,
                      transition: {
                        y: {
                          stiffness: 1000
                        }
                      }
                    }
                  };
                  return (
                    <motion.div
                      className={[css.Item].join(' ')}
                      key={`Item_${index}`}
                      onMouseOver={() => {
                        setHovered(navLink);
                      }}
                    >
                      <Link
                        key={`Item_${index}`}
                        href={navLink.pathname}
                      >
                        <motion.a
                          className={[css.Menu].join(' ')}
                          variants={MenuVariant}
                          onClick={() => {
                            if (!(navLink.submenu && navLink.submenu.length > 0)) {
                              toggleOpen();
                            }
                          }}
                          style={{
                            color: (!isSelected ? vars.white : navLink.color || vars.white)
                          }}
                        >
                          {navLink.label}
                          {navLink.submenu && navLink.submenu.length > 0 && (
                            <Arrow
                              style={{
                                marginLeft: '0.75em'
                              }}
                              color={!isSelected ? vars.white : navLink.color || vars.white}
                              direction={isSelected ? 'top' : 'bottom'}
                            />
                          )}
                        </motion.a>
                      </Link>
                      {isSelected === true && (
                        <motion.div
                          layoutId="selected"
                          className={css.Selected}
                          initial={false}
                          animate={{
                            backgroundColor: navLink.backgroundColor
                          }}
                          transition={{
                            type: 'spring',
                            stiffness: 500,
                            damping: 30,
                            duration: 500
                          }}
                        />
                      )}
                      {isHovered === true && (
                        <motion.div
                          layoutId="hovered"
                          className={css.Hover}
                          initial={false}
                          animate={{
                            backgroundColor: navLink.backgroundColor
                          }}
                          transition={{
                            type: 'spring',
                            stiffness: 500,
                            damping: 30,
                            duration: 500
                          }}
                        />
                      )}
                      {navLink.submenu && navLink.submenu.length > 0 && (
                        <motion.div
                          className={[css.SubMenu].join(' ')}
                          animate={{
                            height: isSelected ? 'auto' : 0,
                          }}
                        >
                          {navLink.submenu.map((submenuItem, index) => {
                            const baseUrl = window.location.href.split('#')[0].replace(/\/([^\/]+)\/$/, '');
                            const submenuItemUrl = `${baseUrl}${navLink.pathname}`;
                            const submenuItemHash = `${baseUrl}${navLink.pathname}#${submenuItem.pathname}`;
                            const isSelected = (hash === submenuItemHash);
                            return (
                              <motion.span
                                key={`Item_${index}`}
                                className={[css.SubMenuItem, (isSelected ? css.Active : '')].join(' ')}
                                variants={MenuVariant}
                                onClick={() => {
                                  // window.location.href = submenuItemHash;
                                  router.push(submenuItemUrl, submenuItemHash, { shallow: true });
                                  toggleOpen();
                                }}
                              >
                                {submenuItem.label}
                              </motion.span>
                            );
                          })}
                        </motion.div>
                      )}
                    </motion.div>
                  );
                })}
              </motion.nav>
              <button
                className={[cssGlobal.Download, css.Download].join(' ')}
                onClick={() => {
                  setShowDownloadModal(true);
                }}
              >
                Télécharger {!(selected && selected.pathname && selected.pathname.length > 0) ? 'le PDF complet' : ''}
              </button>
              <Footer
                className={[css.Footer].join(' ')}
                onClick={() => {
                  toggleOpen(true);
                }}
              />
            </SimpleBarReact>
          </motion.div>
          <BurgerToggle
            toggle={() => toggleOpen()}
          />
        </motion.div>
      </AnimateSharedLayout>
    </div>
  );
};

export default BurgerMenu;
