const packageJson = require('../package.json');

module.exports = {
  name: 'Home',
  title: 'Livret d\'accueil SNCF',
  sentry: {
    dsn: 'SENTRY_DSN',
    org: 'SENTRY_ORG',
    project: 'SENTRY_PROJECT',
    release: 'SENTRY_RELEASE'
  },
  packageJson
};
