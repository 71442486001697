import Modal from 'components/Layout/Modal';
import Link from 'next/link';
import React from 'react';
import cssGlobal from 'styles/global.scss';
import css from './DownloadModal.scss';

const DownloadModal = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      setIsOpen={(show) => {
        props.setIsOpen(show);
      }}
      className={[props.className, css.DownloadModal].join(' ')}
    >
      <h2>
        Votre livret est disponible en version PDF
      </h2>
      <p>
        Vous pouvez le télécharger et l’imprimer si vous le souhaitez.
      </p>
      <nav>
        {!!props.pdf && (
          <Link
            href={props.pdf}>
            <a
              className={cssGlobal.Download}
              target="_blank"
              download
            >
              Télécharger le PDF de cette page
            </a>
          </Link>
        )}
        <Link
          href={'/documents/livret-accueil.pdf'}>
          <a
            className={cssGlobal.Download}
            target="_blank"
            download
          >
            Télécharger le PDF complet
          </a>
        </Link>
      </nav>
    </Modal>
  );
};

export default DownloadModal;
