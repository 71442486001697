import BurgerMenu from 'components/Domain/BurgerMenu';
import DownloadModal from 'components/Domain/DownloadModal';
import Arrow from 'components/Layout/Arrow';
import { AnimateSharedLayout, motion } from 'framer-motion';
import isMobile from 'lib/isMobile';
import isTablet from 'lib/isTablet';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { fromEvent } from 'rxjs';
import SimpleBarReact from 'simplebar-react';
import cssGlobal from 'styles/global.scss';
import vars from 'styles/vars.scss';
import UAParser from 'ua-parser-js';
import css from './Header.scss';

export const navLinks = [
  {
    pathname: '/mon-groupe',
    label: 'Mon groupe',
    backgroundColor: vars.purple,
    submenu: [
      {
        'pathname': 'histoire',
        'label': 'Son histoire',
      },
      {
        'pathname': 'organisation',
        'label': 'Son organisation',
      },
      {
        'pathname': 'ambitions',
        'label': 'Son projet d\'entreprise',
      },
      {
        'pathname': 'principes',
        'label': 'Ses principes éthiques',
      },
      {
        'pathname': 'inclusif',
        'label': 'Un groupe inclusif',
      },
      {
        'pathname': 'fondamentaux',
        'label': 'Ses fondamentaux',
      },
      {
        'pathname': 'concurrents',
        'label': 'Ses concurrents',
      },
      {
        'pathname': 'eco-reponsable',
        'label': 'Un groupe écoresponsable',
      },
      {
        'pathname': 'innovant',
        'label': 'Un groupe innovant',
      },
      {
        'pathname': 'implantation',
        'label': 'Son implantation',
      },
      {
        'pathname': 'investir',
        'label': 'M’investir au sein du groupe',
      },
      {
        'pathname': 'activites',
        'label': 'Les activités sociales et culturelles',
      },
      {
        'pathname': 'sources',
        'label': 'Les sources d’information',
      },
      {
        'pathname': 'vie',
        'label': 'Ma vie dans le groupe',
      },
    ],
    pdf: '/documents/mon-groupe.pdf',
  },
  {
    pathname: '/ma-carriere',
    label: 'Ma carrière',
    backgroundColor: vars.blue,
    submenu: [
      {
        pathname: 'changer-metier-sein-groupe',
        label: 'Changer de métier au sein du groupe',
      },
      {
        pathname: 'mon-parcours-professionnel',
        label: 'Mon parcours professionnel',
      },
      {
        pathname: 'me-former-developper-mes-competences',
        label: 'Me former et développer mes compétences',
      },
    ],
    pdf: '/documents/ma-carriere.pdf',
  },
  {
    pathname: '/mes-informations-rh',
    label: 'Mes informations RH',
    backgroundColor: vars.pink,
    submenu: [
      {
        pathname: 'regles-applicables',
        label: 'Les règles applicables',
      },
      {
        pathname: 'regles-rh',
        label: 'Les règles RH au sein du groupe',
      },
      {
        pathname: 'contrat-travail',
        label: 'Mon contrat de travail',
      },
      {
        pathname: 'periode-essai',
        label: 'Ma période d’essai',
      },
      {
        pathname: 'duree-travail',
        label: 'La durée hebdomadaire du travail',
      },
      {
        pathname: 'remuneration',
        label: 'Ma rémunération',
      },
      {
        pathname: 'bulletin-paie',
        label: 'Mon bulletin de paie',
      },
      {
        pathname: 'regimes',
        label: 'Mes régimes de santé, de prévoyance et de retraite',
      },
      {
        pathname: 'conges',
        label: 'Mes congés payés',
      },
      {
        pathname: 'evaluations',
        label: 'Mes évaluations annuelles',
      },
      {
        pathname: 'teletravail',
        label: 'Le télétravail',
      },
      {
        pathname: 'rdv-medicaux',
        label: 'Mes rendez-vous médicaux de santé au travail',
      },
      {
        pathname: 'syndicats',
        label: 'La représentation syndicale au sein de la SNCF',
      },
      {
        pathname: 'aller-loin',
        label: 'Pour aller plus loin',
      },
    ],
    pdf: '/documents/mes-informations-rh.pdf',
  },
  {
    pathname: '/mes-avantages',
    label: 'Mes avantages',
    backgroundColor: vars.orange,
    submenu: [
      {
        pathname: 'aides-loger',
        label: 'Mes solutions logement',
      },
      {
        pathname: 'facilites-circulation',
        label: 'Mes facilités de circulation',
      },
      {
        pathname: 'action-sociale',
        label: 'L’Action sociale - Optim’services',
      },
      {
        pathname: 'plan-epargne',
        label: 'Mon plan d’épargne de groupe (PEG)',
      },
      {
        pathname: 'aller-loin',
        label: 'Pour aller plus loin',
      },
    ],
    pdf: '/documents/mes-avantages.pdf',
  },
  {
    pathname: '/mes-apps-contacts-utiles',
    label: 'Mes applis & contacts utiles',
    backgroundColor: vars.green,
    pdf: '/documents/mes-apps-contacts-utiles.pdf',
  },
  {
    pathname: '/chiffres-cles',
    label: 'Chiffres clés',
    backgroundColor: vars.lemon,
    color: '#333333',
    submenu: [
      {
        pathname: 'collaborateurs',
        label: 'Les collaborateurs',
      },
      {
        pathname: 'emploi-formation',
        label: 'L’emploi et la formation',
      },
      {
        pathname: 'chiffre-affaire',
        label: 'Le chiffre d’affaires',
      },
      {
        pathname: 'nombre-voyageurs',
        label: 'Le nombre de voyageurs transportés',
      },
      {
        pathname: 'trafic-ferroviaire',
        label: 'Le trafic ferroviaire',
      },
      {
        pathname: 'reseau-ferre',
        label: 'Le réseau ferré',
      },
      {
        pathname: 'batiment-immobilier',
        label: 'Le bâtiment et l’immobilier',
      },
      {
        pathname: 'innovation-partenariats',
        label: 'L’innovation et les partenariats',
      },
      {
        pathname: 'investissement-achats',
        label: 'L’investissement et les achats',
      }
    ],
    pdf: '/documents/chiffres-cles.pdf',
  },
];

const Header = (props) => {
  const router = useRouter();
  const [hash, setHash] = useState('');
  const defaultLink = navLinks.filter((link) => {
    const matchPattern = new RegExp(`${link.pathname}/?`);
    return !!router.pathname.match(matchPattern);
  })[0] || { pathname: null };
  const [selected, setSelected] = useState(defaultLink);
  const [hovered, setHovered] = useState(null);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const scrollDOM = useRef();
  const scrollInnerDOM = useRef();
  const [showSubmenuArrows, setShowSubmenuArrows] = useState(false);

  const [tablet, setTablet] = useState(false);
  const [mobile, setMobile] = useState(false);
  const refreshScreensize = useCallback(() => {
    setMobile(isMobile());
    setTablet(isTablet());
  }, []);

  useEffect(() => {
    if (global.window) {
      window.removeEventListener('resize', refreshScreensize);
      window.addEventListener('resize', refreshScreensize);
      refreshScreensize();
    }
  }, []);

  useEffect(() => {
    const newLink = navLinks.filter((link) => {
      const matchPattern = new RegExp(`${link.pathname}/?`);
      return !!router.pathname.match(matchPattern);
    })[0] || { pathname: null };
    if (newLink) {
      setSelected(newLink);
      setHovered(null);
    }
  }, [router]);

  useEffect(() => {
    if (window) {
      window.dispatchEvent(new Event('resize'));
    }
  }, [selected]);

  useEffect(() => {
    if (!scrollDOM) return;
    if (!scrollDOM.current) return;
    const scrollElement = scrollDOM.current.getScrollElement();
    const scrollWidth = scrollElement.getBoundingClientRect().width;
    if (!scrollInnerDOM) return;
    if (!scrollInnerDOM.current) return;
    const scrollInnerElement = scrollInnerDOM.current;
    if (!scrollInnerElement) return;
    const scrollInnerRect = scrollInnerElement.getBoundingClientRect();
    const scrollInnerWidth = parseInt(scrollInnerRect.width, 10);
    const threshold = 100;

    setShowSubmenuArrows(scrollWidth < (scrollInnerWidth + threshold));
  }, [scrollDOM, selected]);

  const updateHash = () => {
    clearTimeout(global.HeaderHashUpdateTimer);
    global.HeaderHashUpdateTimer = setTimeout(() => {
      setHash(location.hash);
    }, 0);
  };

  if (process.browser) {
    useEffect(() => {
      if (!window) return;
      window.removeEventListener('scrollHashChange', updateHash);
      window.addEventListener('scrollHashChange', updateHash);
      const event = new Event('scrollHashChange');
      window.dispatchEvent(event);
      setHash(location.hash);
    }, [location]);
    useEffect(() => {
      if (!process.browser) return;
      global.HeaderHashUpdateTimer = setTimeout(() => {}, 0);
    }, []);
  }

  useEffect(() => {
    // Window not defined
    if (!global.window) return;

    // Resize
    fromEvent(global.window, 'onhashchange').subscribe(() => {
      setHash(location.hash);
    });
    global.window.dispatchEvent(new Event('onhashchange'));
  }, [global.window]);

  const currentHash = useCallback(() => {
    return (process.browser) ? location.hash : hash;
  }, [process.browser, hash])();

  const isOSAllowed = (() => {
    const localUA = new UAParser();
    if (localUA && localUA.getOS() && localUA.getOS().name && localUA.getOS().name === 'iOS') {
      return false;
    }
    return true;
  })();

  return (
    <React.Fragment>
      <DownloadModal
        isOpen={showDownloadModal}
        setIsOpen={(show) => {
          setShowDownloadModal(show);
        }}
        pdf={(selected && selected.pdf) ? selected.pdf : null}
      />
      <div
        {...props}
        className={[props.className, css.Header].join(' ')}
      >
        <div
          className={css.Main}
        >
          <div
            className={css.Logo}
          >
            <Link href="/">
              <a
                onClick={() => {
                  setSelected(null);
                }}
              >
                SNCF
              </a>
            </Link>
          </div>
          <div
            className={css.Welcome}
          >
            <Link href="/sommaire">
              <a
                onClick={() => {
                  setSelected(null);
                }}
              >
                Livret d'accueil
              </a>
            </Link>
          </div>
          <AnimateSharedLayout>
            <nav
              className={css.Menu}
              onMouseLeave={() => {
                setTimeout(() => {
                  setHovered(null);
                }, 250);
              }}
            >
              {navLinks.map((item, index) => {
                const isSelected = (selected && selected.pathname === item.pathname);
                const isHovered = (hovered && hovered.pathname === item.pathname);
                return (
                  <Link
                    key={`${index}_${item.pathname}`}
                    href={item.pathname}
                  >
                    <a
                      className={[
                        css.MenuItem,
                        isSelected ? css.SelectedMenuItem : null,
                        isOSAllowed ? css.SelectedMenuItemOSAllowed : css.SelectedMenuItemOSNotAllowed,
                      ].join(' ')}
                      style={{
                        color: isSelected && !!item.color ? item.color : 'inherit',
                      }}
                      onClick={() => {
                        setSelected(item);
                      }}
                      onMouseOver={() => {
                        setHovered(item);
                      }}
                    >
                      <div
                        className={css.MenuText}
                      >
                        {item.label}
                      </div>
                      {isSelected === true && (
                        <motion.div
                          layoutId="selected"
                          className={css.Hover}
                          initial={false}
                          animate={{
                            backgroundColor: item.backgroundColor
                          }}
                          transition={{
                            type: 'spring',
                            stiffness: 500,
                            damping: 30,
                            duration: 500
                          }}
                        />
                      )}
                      {isHovered === true && (
                        <motion.div
                          layoutId="hovered"
                          className={css.Hover}
                          initial={false}
                          animate={{
                            backgroundColor: item.backgroundColor
                          }}
                          transition={{
                            type: 'spring',
                            stiffness: 500,
                            damping: 30,
                            duration: 500
                          }}
                        />
                      )}
                    </a>
                  </Link>
                );
              })}
            </nav>
          </AnimateSharedLayout>
          {(mobile || tablet) && (
            <BurgerMenu
              navLinks={navLinks}
              selected={selected}
              hash={location.hash}
              setShowDownloadModal={setShowDownloadModal}
            />
          )}
          <button
            className={[cssGlobal.Download, css.Download].join(' ')}
            onClick={() => {
              setShowDownloadModal(true);
            }}
          >
            Télécharger {!(selected && selected.pathname && selected.pathname.length > 0) ? 'le PDF complet' : ''}
          </button>
        </div>
        {selected && selected.submenu && (
          <div
            className={css.SubMenuWrapper}
          >
            {isOSAllowed && (
              <React.Fragment>
                <button
                  className={[css.Slide, css.SlideLeft, (showSubmenuArrows ? css.Visible : '')].join(' ')}
                  onMouseDown={() => {
                    const event = new Event('scroll');
                    document.getElementById('scrollDOM').dispatchEvent(event);

                    if (!scrollDOM) return;
                    if (!scrollDOM.current) return;
                    const scrollElement = scrollDOM.current.getScrollElement();
                    scrollElement.scrollBy({
                      top: 0,
                      left: -200,
                      behavior: 'smooth'
                    });

                    if (mobile) {
                      const subMenuDOMItems = Object.values(document.getElementById('RootSubMenu').getElementsByClassName('SubMenuItem'));
                      const selectedIndex = subMenuDOMItems.reduce((selected, subMenuDOMItem, index) => {
                        const isSelected = subMenuDOMItem.className.match(css.IsSelected);
                        if (isSelected !== null) {
                          selected = index;
                        }
                        return selected;
                      }, 0);
                      try {
                        subMenuDOMItems[selectedIndex - 1].click();
                        setTimeout(() => {
                          const event = new Event('scroll');
                          document.getElementById('scrollDOM').dispatchEvent(event);
                        }, 500);
                      } catch (err) {}
                    }
                  }}
                >
                  <Arrow
                    direction={'left'}
                    color={mobile ? selected.color || vars.white : null}
                    rerenderOn={selected}
                  />
                </button>
                <button
                  className={[css.Slide, css.SlideRight, (showSubmenuArrows ? css.Visible : '')].join(' ')}
                  onMouseDown={() => {
                    const event = new Event('scroll');
                    document.getElementById('scrollDOM').dispatchEvent(event);

                    if (!scrollDOM) return;
                    if (!scrollDOM.current) return;
                    const scrollElement = scrollDOM.current.getScrollElement();
                    scrollElement.scrollBy({
                      top: 0,
                      left: 200,
                      behavior: 'smooth'
                    });

                    if (mobile) {
                      const subMenuDOMItems = Object.values(document.getElementById('RootSubMenu').getElementsByClassName('SubMenuItem'));
                      const selectedIndex = subMenuDOMItems.reduce((selected, subMenuDOMItem, index) => {
                        const isSelected = subMenuDOMItem.className.match(css.IsSelected);
                        if (isSelected !== null) {
                          selected = index;
                        }
                        return selected;
                      }, 0);
                      try {
                        subMenuDOMItems[selectedIndex + 1].click();
                        setTimeout(() => {
                          const event = new Event('scroll');
                          document.getElementById('scrollDOM').dispatchEvent(event);
                        }, 500);
                      } catch (err) {}
                    }
                  }}
                >
                  <Arrow
                    direction={'right'}
                    color={mobile ? selected.color || vars.white : null}
                    rerenderOn={selected}
                  />
                </button>
              </React.Fragment>
            )}
            <SimpleBarReact
              id={'RootSubMenu'}
              className={css.SubMenuScroll}
              autoHide={false}
              ref={scrollDOM}
            >
              <motion.nav
                initial="hidden"
                animate="visible"
                variants={{
                  hidden: {
                    height: 0
                  },
                  visible: {
                    height: 'auto'
                  },
                }}
                className={css.SubMenu}
              >
                <div
                  className={css.SubMenuNav}
                  ref={scrollInnerDOM}
                >
                  <div
                    className={css.SubMenuMargin}
                  />
                  <AnimateSharedLayout>
                    {(isOSAllowed) && selected.submenu.map((item, index) => {
                      const submenuItemHash = `#${item.pathname}`;
                      const isSelected = (currentHash === submenuItemHash);
                      return (
                        <Link
                          key={`submenu_${index}`}
                          href={submenuItemHash}
                        >
                          <a
                            className={[isSelected.toString(), css.SubMenuItem, 'SubMenuItem', (isSelected ? css.IsSelected : ''), (isSelected ? 'IsSelected' : '')].join(' ')}
                            style={{
                              color: mobile ? selected.color || vars.white : null,
                              display: mobile ? (isSelected ? 'block' : 'none') : 'flex',
                              fontSize: mobile ? '12px' : '13px',
                            }}
                          >
                            {item.label}
                            {isSelected && (
                              <motion.div
                                layoutId="background"
                                className={css.SelectedSubMenuItem}
                                initial={false}
                                transition={{
                                  type: 'spring',
                                  stiffness: 500,
                                  damping: 30,
                                  duration: 500
                                }}
                              />
                            )}
                          </a>
                        </Link>
                      );
                    })}
                    {!(isOSAllowed) && selected.submenu.map((item, index) => {
                      const submenuItemHash = `#${item.pathname}`;
                      const isSelected = (currentHash === submenuItemHash);
                      return isOSAllowed && (
                        <Link
                          key={`submenu_${index}`}
                          href={submenuItemHash}
                        >
                          <a
                            id={'SelectedSubMenuItemMobile'}
                            className={[isSelected.toString(), css.SubMenuItem, 'SubMenuItemMobile', (isSelected ? css.IsSelected : ''), (isSelected ? 'IsSelected' : '')].join(' ')}
                            style={{
                              color: mobile ? selected.color || vars.white : null,
                              display: mobile ? (isSelected ? 'block' : 'none') : 'flex',
                              fontSize: mobile ? '12px' : '13px',
                            }}
                          >
                            {item.label}
                            {isSelected && (
                              <motion.div
                                layoutId="background"
                                className={css.SelectedSubMenuItem}
                                initial={false}
                                transition={{
                                  type: 'spring',
                                  stiffness: 500,
                                  damping: 30,
                                  duration: 500
                                }}
                              />
                            )}
                          </a>
                        </Link>
                      );
                    })}
                  </AnimateSharedLayout>
                  <div
                    className={css.SubMenuMargin}
                  />
                </div>
              </motion.nav>
            </SimpleBarReact>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Header;
